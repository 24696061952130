const faqs = [
  {
    question: "1. Requisitos generales de visa canadiense:",
    answer: (
      <div>
        <p>- Formulario de solicitud: Completá el formulario de solicitud de visa canadiense de manera precisa y veraz. <br/> <br/>
        - Pasaporte válido: Asegúrate de tener un pasaporte válido con una fecha de vencimiento adecuada y al menos una página en blanco para sellos de entrada y salida. <br/> <br/>
       - Fotografías: Proporcioná fotografías recientes y cumpliendo con las especificaciones requeridas. <br/> <br/>
       - Prueba de fondos suficientes: Demuestra que cuentas con los recursos financieros necesarios para cubrir tus gastos durante tu estadía en Canadá <br/> <br/>
       - Prueba de lazos fuertes con tu país de origen: Es importante demostrar que tienes motivos para regresar a tu país de origen, como lazos familiares, empleo estable, bienes raíces u otros compromisos. <br/> <br/>
       - Seguro médico de viaje: Contar con un seguro médico es un requisito obligatorio para obtener una visa de entrada a Canadá. Los oficiales de inmigración pueden solicitar pruebas de que tienes cobertura médica adecuada antes de otorgarte la visa. <br/> <br/>
</p>
      </div>
    ),
  },
  {
    question: "2. Documentos adicionales según el tipo de visa:",
    answer: (
      <div>
      <p>- Visas de visitante: Si planeas visitar a amigos o familiares, puede ser necesario contar con una carta de invitación que detalle los detalles del anfitrión y el propósito de tu visita.<br/> <br/>
     - Visas de estudio: Deberás presentar una carta de aceptación de una institución educativa canadiense y demostrar que tienes los fondos para cubrir tus estudios y tu manutención. <br/> <br/>
     - Visas de trabajo: Dependiendo del tipo de trabajo y duración, es posible que debas obtener una oferta de empleo y un permiso de trabajo antes de solicitar la visa.<br/> <br/>
     - Presenta una solicitud sólida: Prepará todos los documentos requeridos con anticipación y asegúrate de que estén completos y actualizados. <br/> <br/>
     Verificá los tiempos de procesamiento de la visa y presentá tu solicitud con suficiente anticipación para evitar retrasos. <br/> <br/>
</p>
    </div>
    ),
  },
  {
    question: "3. Visa de Canadá para Argentinos ",
    answer: (
      <div>
        <p>
        Los ciudadanos argentinos que posean una visa estadounidense válida o hayan obtenido una visa de visitante canadiense en la última década podrán solicitar la eTA, un procedimiento en línea, rápido y económico. Esta alternativa beneficiará a los argentinos que deseen viajar a Canadá y se espera fomentar los intercambios comerciales, culturales, académicos y turísticos entre ambos países. 
 
        </p> 
      </div>
    ),
  },

];

export default faqs;
